import React from 'react'
import PropTypes from 'prop-types'

import './all.sass'

const PageFrame = ({ children, backgroundColor, height }) => {
  return (
    <div style={{ backgroundColor: backgroundColor, height: height}} className="pageFrameContainer">
      {children}
    </div>
  );
}

PageFrame.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  backgroundColor: PropTypes.string
}

export default PageFrame
