import React from 'react';
import Slider from "react-slick";
import ReactPlayer from 'react-player'

import './all.sass'
import "../../node_modules/slick-carousel/slick/slick.css"; 
import "../../node_modules/slick-carousel/slick/slick-theme.css";

class VideoSlider extends React.Component {
  /*constructor(props) {
    super(props);

    //this.state...
  }*/

  render() {
    
    function PrevArrow(props) {
      const { className, style, onClick } = props;
      return (
        <div
          className={className}
          style={{ ...style, display: "block", background: '#333', borderRadius: 50 }}
          onClick={onClick}
        />
      );
    }

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: <PrevArrow />
    };

    const slides = this.props.videoLinks.map((link, index) => {
      return (
        <div key={index} className="vSlide">
          <ReactPlayer
            url={link.url}
            className='videoPlayer'
            width='100%'
            height='100%'
          />
        </div>
      );
    })

    return (
      <Slider className="videoSlider" {...settings}>
        {slides}
      </Slider>
    );
  }
};

export default VideoSlider;