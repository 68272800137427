import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faChevronUp, faSyncAlt, faChevronCircleUp, faChevronCircleDown } from '@fortawesome/free-solid-svg-icons'
import { Spring } from 'react-spring/renderprops'
import moment from 'moment'
import Fade from 'react-reveal/Fade';
import { animateScroll as scroll } from 'react-scroll';
import Img from "gatsby-image";

import Layout from '../components/Layout'
import PageFrame from '../components/PageFrame'
import Navbar from '../components/Navbar'
import Social from '../components/Social'
import TrackPlayer from '../components/TrackPlayer'
import VideoSlider from '../components/VideoSlider'
import EventsCarousel from '../components/EventsCarousel'
import Footer from '../components/Footer'

var Spinner = require('react-spinkit');

function sleep (time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export class IndexPageTemplate extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      scrollValue: 0,
      touchTimestamp: 0,
      touchPosition: 0,
      aboutOpen: false,
      aboutOpenFinish: false,
      windowHeight: 0,
      windowWidth: 0,
      eventsType: 'past',
      events: this.props.events,
      scrolled: false,
      pageLoaded: false,
      language: 'es',
      webPSupport: false
    };

    this.aboutTextRef = React.createRef();
  }

  componentDidMount = () => {
    this.checkForWebP();

    sleep(3000).then(() => {
      this.setState({ pageLoaded: true })
    });

    this.setState({ language: 'es', windowHeight: window.document.documentElement.clientHeight, windowWidth: window.document.documentElement.clientWidth, eventsType: 'past', events: this.props.events.filter(element => moment(element.date, 'DD/MM/YYYY') < moment().subtract(1, 'days')).sort((a,b) => moment(b.date, 'DD/MM/YYYY') - moment(a.date, 'DD/MM/YYYY')) });

    if (window.document.documentElement.clientWidth > 1024) {
      window.addEventListener('wheel', this.onScroll, { passive: false });
      window.addEventListener('keydown', this.onKeyDown, { passive: false });
      window.addEventListener('touchmove', this.onTouchMove, { passive: false });
    }
  }

  componentWillUnmount = () => {
    if (window.document.documentElement.clientWidth > 1024) {
      window.removeEventListener('wheel', this.onScroll);
      window.removeEventListener('keydown', this.onKeyDown);
      window.removeEventListener('touchmove', this.onTouchMove);
    }
  }

  checkForWebP = async () => {
    if (!window.self.createImageBitmap) return false;
    
    const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
    const blob = await fetch(webpData).then(r => r.blob());
    const supportsWebP = await createImageBitmap(blob).then(() => true, () => false);
    
    this.setState({ webPSupport : supportsWebP });
  }

  switchLanguage = (language) => {
    if (this.state.language !== language) {
      this.setState({ language: language });
    }
  }

  switchEvents = (type) => {
    if (type !== this.state.eventsType && type === 'past') {
      this.setState({ eventsType: type, events: this.props.events.filter(element => moment(element.date, 'DD/MM/YYYY') < moment().subtract(1, 'days')).sort((a,b) => moment(b.date, 'DD/MM/YYYY') - moment(a.date, 'DD/MM/YYYY')) });
    } else if (type !== this.state.eventsType) {
      this.setState({ eventsType: type, events: this.props.events.filter(element => moment(element.date, 'DD/MM/YYYY') >= moment().subtract(1, 'days')).sort((a,b) => moment(a.date, 'DD/MM/YYYY') - moment(b.date, 'DD/MM/YYYY')) });
    }
  }

  toggleAboutFragment = () => {
    if (this.state.aboutOpen === false) {
      this.setState((state, props) => ({
        aboutOpen: !state.aboutOpen
      }));
      sleep(500).then(() => {
        this.setState((state, props) => ({
          aboutOpenFinish: !state.aboutOpenFinish
        }));
      })
    } else {
      this.setState((state, props) => ({
        aboutOpenFinish: !state.aboutOpenFinish
      }));
      sleep(500).then(() => {
        this.setState((state, props) => ({
          aboutOpen: !state.aboutOpen
        }));
      })
    }
  }

  scrollAboutTextUp = () => {
    if (this.aboutTextRef) this.aboutTextRef.current.scrollTop -= 30;
  }

  scrollAboutTextDown = () => {
    if (this.aboutTextRef) this.aboutTextRef.current.scrollTop += 30;
  }

  scrollTo = (link) => {
    switch(link) {
      case 'tracks':
        this.setState({ scrollValue: 500, scrolled: true });
        break;
      case 'dates':
        scroll.scrollTo(this.state.windowHeight);
        this.setState({ scrolled: true });
        break;
      case 'sets':
        scroll.scrollTo(this.state.windowHeight * 2);
        this.setState({ scrolled: true });
        break;
      case 'booking':
        scroll.scrollToBottom();
        this.setState({ scrolled: true });
        break;
      default:
        this.setState({ scrollValue: 0, scrolled: false });
        scroll.scrollToTop();
    }
  }

  onScroll = (e) => {
    this.setState(function(state, props) {
      const sp = state.scrollValue + e.deltaY < 0 ? 0 : state.scrollValue + e.deltaY;
      if (window.pageYOffset === 0 && (sp < 700 || e.deltaY < 0)) {
        e.preventDefault();
        this.setState({ scrolled: false });
      }

      return { scrollValue: sp }
    });
  }

  onKeyDown = (e) => {
    e.preventDefault();
  }

  onTouchMove = (e) => {
    this.setState(function(state, props) {
      let sp, timestamp, tp, delta;

      if (e.timeStamp - state.touchTimestamp < 30) {
        delta = e.touches[0].clientY - state.touchPosition;
      } else {
        delta = 0;
      }

      sp = state.scrollValue - delta * 1.5;

      timestamp = e.timeStamp;
      tp = e.touches[0].clientY;

      const finalSp = sp < 0 ? 0 : sp;

      if (window.pageYOffset === 0 && (sp < 700 || delta > 0)) {
        e.preventDefault();
        this.setState({ scrolled: false });
      }

      return { 
        scrollValue: finalSp,
        touchTimestamp: timestamp,
        touchPosition: tp
      }
    });
  }

  render() {
    const mOpacity = this.state.scrollValue < 500 ? this.state.scrollValue / 500 : 1;

    const datesSvgEndpoint = this.state.windowHeight * 0.70 + 60 + (this.state.windowHeight * 0.30 - 107) / 2;
    const datesTitleStartPoint = 60 + (this.state.windowHeight * 0.30 - 107) / 2;

    let linePercentage;
    if (this.state.scrollValue > 0) {
      linePercentage = this.state.scrollValue < 500 ? this.state.scrollValue / 500 * window.innerHeight : window.innerHeight;
    } else {
      linePercentage = 0;
    }

    let aboutFragmentWidth, aboutFragmentHeight;
    if (this.state.aboutOpen) {
      if (this.state.windowWidth < 768) {
        aboutFragmentWidth = this.state.windowWidth * 0.9;
        aboutFragmentHeight = this.state.windowHeight * 0.87;
      } else if (this.state.windowWidth < 1025) {
        aboutFragmentWidth = this.state.windowWidth * 0.5;
        aboutFragmentHeight = this.state.windowHeight * 0.6;
      } else {
        aboutFragmentWidth = this.state.windowWidth * 0.25;
        aboutFragmentHeight = this.state.windowHeight * 0.8;
      }
    } else {
      aboutFragmentHeight = 0;
      aboutFragmentWidth = 0;
    }
    
    const showScrollBtn = (this.state.windowWidth > 1024 && (this.state.scrollValue >= 500 || this.state.scrolled)) || (this.state.windowWidth < 1025 && this.state.scrollValue >= 500);

    const mainFrameBg = this.state.windowWidth > 1024 ? this.props.desktopImg : this.props.mobileImg;

    return (
      <div className="noScrollBody" style={{ height: this.state.windowHeight }}>

        <div className="rotateOverlay" style={{ height: this.state.windowWidth + 10 }}>
          <FontAwesomeIcon icon={faSyncAlt} className="rotateOverlayIcon" size="3x" color="#333" />
          <h1 className="rotateOverlayText">{this.state.language === 'en' ? "PLEASE ROTATE YOUR DEVICE" : "POR FAVOR, ROTE SU DISPOSITIVO"}</h1>
        </div>
        
        <Spinner name="line-scale-pulse-out" color="#475C7A" className="spinnerOverlay" style={{height: this.state.windowHeight * 3, display: this.state.pageLoaded ? 'none' : 'flex'}}/>
        
        <button style={{ display: showScrollBtn ? 'block' : 'none' }} className="scrollToTopBtn" onClick={() => this.scrollTo('top')}>
          <FontAwesomeIcon icon={faChevronUp} className="bigIcon" size="lg" color="#fff" />
        </button>
        
        <div
          className="siteBg"
          style={{
            display: this.state.pageLoaded ? 'block' : 'none',
            height: this.state.windowHeight * 3.47,
            backgroundColor: '#050505',
            backgroundPosition: 'top left',
            backgroundAttachment: 'fixed'
          }} 
        >
          <PageFrame backgroundColor={"transparent"} height={this.state.windowHeight}>
            <svg className="svgContainer" style={{ height: this.state.windowHeight }}>
              <path d={"M " + (this.state.scrollValue > 0 ? this.state.windowWidth * 0.2 * 0.75 : 0) + " 0 L " + (this.state.scrollValue > 0 ? this.state.windowWidth * 0.2 * 0.75 : 0) + " " + linePercentage + " Z"} stroke="#475C7A" fill="transparent" strokeWidth="4"/>
              Sorry, your browser does not support inline SVG.
            </svg>
            <div id="frameBgCover" style={{ opacity: mOpacity, height: this.state.windowHeight }}>
              <video id="frameBgVideo" loop autoPlay muted playsInline>
                <source src={'https://s3.us-east-2.amazonaws.com/fedebell-project/Federico+Bell.mp4'} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <TrackPlayer
                parentProps={this.props}
                language={this.state.language}
              />
            </div>
            <div 
              className="mainFrame" 
              style={{
                height: this.state.windowHeight,
                maxHeight: this.state.windowHeight,
                opacity: 1 - mOpacity,
                pointerEvents: this.state.scrollValue > 250 ? 'none' : 'auto'
              }}>
              <Img
                className="mainFrameBackgroundImage"
                style={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  width: this.state.windowWidth,
                  height: this.state.windowHeight
                }}
                fluid={mainFrameBg.childImageSharp[this.state.webPSupport ? 'webP' : 'normal']}
                alt={"BackgroundImage"}
              />

              <Spring
                from={{ opacity: 0 }}
                to={{ opacity: this.state.aboutOpen ? 0 : 1 }}>
                { props =>
                  <Navbar style={props} disabled={this.state.aboutOpen} language={this.state.language} switchLanguage={this.switchLanguage} toggleAboutFragment={this.toggleAboutFragment} scrollTo={this.scrollTo}/>
                }
              </Spring>
              <Spring
                from={{ opacity: 0 }}
                to={{ opacity: this.state.aboutOpen ? 0 : 1 }}>
                { props => <Social style={props} aboutOpen={this.state.aboutOpen} parentProps={this.props} /> }
              </Spring>
              <Spring
                from={{ width: 0, height: 0, opacity: 0 }}
                to={{ 
                  width: aboutFragmentWidth,
                  height: aboutFragmentHeight,
                  opacity: this.state.aboutOpenFinish ? 1 : 0
                }}>
                { props => {
                    const aboutText = this.state.language === 'en' ? this.props.aboutText : this.props.aboutTextEs;
                    const formattedAboutText = aboutText.split("*").map((paragraph, index) => {
                      return (
                        <p key={index} style={{ opacity: props.opacity }} className="aboutText">
                          {paragraph}
                          <br/>
                        </p>
                      )
                    })

                    return (<div style={{width: props.width, height: props.height}} className="aboutFragment">
                      <button disabled={!this.state.aboutOpenFinish} style={{ opacity: props.opacity }} className="aboutBtn" onClick={this.toggleAboutFragment}>
                        <FontAwesomeIcon icon={faTimes} className="socialIcon" size="lg" color="#fff" />
                      </button>
                      <h1 style={{ opacity: props.opacity }} className="aboutHeading">federico bell</h1>
                      <div className="aboutTextContainer" ref={this.aboutTextRef}>
                        {formattedAboutText}
                      </div>
                      <div className="aboutTextBtnsContainer">
                        <button disabled={!this.state.aboutOpenFinish} style={{ opacity: props.opacity }} className="aboutTextBtn" onClick={this.scrollAboutTextUp}>
                          <FontAwesomeIcon icon={faChevronCircleUp} className="socialIcon" size="lg" color="#fff" />
                        </button>
                        <button disabled={!this.state.aboutOpenFinish} style={{ opacity: props.opacity }} className="aboutTextBtn" onClick={this.scrollAboutTextDown}>
                          <FontAwesomeIcon icon={faChevronCircleDown} className="socialIcon" size="lg" color="#fff" />
                        </button>
                      </div>
                    </div>)
                  }
                }
              </Spring>
            </div>
          </PageFrame>
          <PageFrame backgroundColor={"#fff"} height={this.state.windowHeight}>
            <div className="contrastSeparator">
              <div 
                className="contrastBg"
                style={{
                  backgroundPosition: 'top left'
                }}
              >
                <div className="datesTitleContainer" style={{paddingTop: datesTitleStartPoint}}>
                  <h1 className="datesTitle" style={this.state.language === 'en' ? {fontSize: 37, letterSpacing: 17} : {fontSize: 32, letterSpacing: 14}}>{this.state.language === 'en' ? "DATES" : "EVENTOS"}</h1>
                </div>
                <svg className="datesSvgContainer" style={{ height: this.state.windowHeight }}>
                  <path d={"M 5 0 L 5 " + datesSvgEndpoint + " Z"} stroke="#475C7A" strokeWidth="4"/>
                  Sorry, your browser does not support inline SVG.
                </svg>
              </div>
            </div>
            <EventsCarousel events={this.state.events} language={this.state.language} switchEvents={this.switchEvents} eventsType={this.state.eventsType} height={this.state.windowHeight} webPSupport={this.state.webPSupport}/>
          </PageFrame>
          <PageFrame backgroundColor={"#ecf0f1"} height={this.state.windowHeight}>
            <div 
              className="setsFrame"
              style={{
                backgroundPosition: 'top left'
              }}>
                <div className="sliderContainer">
                  <Fade left>
                    <VideoSlider videoLinks={this.props.videoLinks} />
                  </Fade>
                </div>
                <img alt="" className="setsImage" src={this.state.windowWidth > 1023 ? "/img/fedeSiluetaUse.png" : "/img/fedeSiluetaCrop.png"} /> 
            </div>
          </PageFrame>
          <Footer parentProps={this.props} language={this.state.language} height={this.state.windowHeight} />
        </div>
      </div>
    );
  }
}

IndexPageTemplate.propTypes = {
  desktopImg: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  mobileImg: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  aboutText: PropTypes.string,
  aboutTextEs: PropTypes.string,
  beatportTracks: PropTypes.array,
  videoLinks: PropTypes.array,
  bookingOne: PropTypes.string,
  bookingTwo: PropTypes.string,
  events: PropTypes.arrayOf(PropTypes.shape({date: PropTypes.string, image: PropTypes.oneOfType([PropTypes.object, PropTypes.string])}))
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        desktopImg={frontmatter.desktopImg}
        mobileImg={frontmatter.mobileImg}
        aboutText={frontmatter.aboutText}
        aboutTextEs={frontmatter.aboutTextEs}
        bookingOne={frontmatter.bookingOne}
        bookingTwo={frontmatter.bookingTwo}
        beatportTracks={frontmatter.beatportTracks}
        videoLinks={frontmatter.videoLinks}
        events={frontmatter.events}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
query IndexPageTemplate {
  markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
      frontmatter {
        desktopImg {
          childImageSharp {
            webP: fluid(maxWidth: 1920, quality: 100, toFormat: WEBP) {
              ...GatsbyImageSharpFluid
            }
            normal: fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mobileImg {
          childImageSharp {
            webP: fluid(maxWidth: 1048, quality: 100, toFormat: WEBP) {
              ...GatsbyImageSharpFluid
            }
            normal: fluid(maxWidth: 1048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        aboutText
        aboutTextEs
        bookingOne
        bookingTwo
        beatportTracks {
          title
          author
          streamUrl
        }
        videoLinks {
          url
        }
        events {
          date
          image {
            childImageSharp {
              webP: fluid(maxWidth: 600, quality: 100, toFormat: WEBP) {
                ...GatsbyImageSharpFluid
              }
              normal: fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
