import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSoundcloud } from '@fortawesome/free-brands-svg-icons'

import SoundCloudPlayer from './SoundCloudPlayer'
import BeatportPlayer from './BeatportPlayer'

import './all.sass'

class TrackPlayer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activePlayer: 'beatport'
    };
  }

  onSelect = (playerName) => {
    if (this.state.activePlayer !== playerName) this.setState({ activePlayer: playerName });
  }

  render() {
    return (
      <div className="trackPlayer">
        <div className="trackPlayerHeader">
          {
            this.state.activePlayer === "soundcloud"
            ? <a className="tpPageLink" href="https://soundcloud.com/federico-bell" target="_blank" rel="noopener noreferrer">{this.props.language === 'en' ? "Listen on Soundcloud.com" : "Escuchar en Soundcloud.com"}</a>
            : <a className="tpPageLink" href="https://www.beatport.com/artist/federico-bell/543221" target="_blank" rel="noopener noreferrer">{this.props.language === 'en' ? "Listen on Beatport.com" : "Escuchar en Beatport.com"}</a>
          }
          <div className="tpIconsContainer">
            <button style={{backgroundColor: this.state.activePlayer === 'soundcloud' ? '#475C7A' : 'transparent'}} className="trackPlayerBtn" onClick={() => window.open('https://soundcloud.com/federico-bell', '_blank')}>
              <FontAwesomeIcon icon={faSoundcloud} className="socialIcon" size="lg" color="#fff" />
            </button>
            <button style={{backgroundColor: this.state.activePlayer === 'beatport' ? '#475C7A' : 'transparent'}} className="trackPlayerBtn" onClick={() => this.onSelect('beatport')}>
              <img alt="" height="27" width="27" src="/img/beatport.png" />
            </button>
          </div>
        </div>
        { /*this.state.activePlayer === "soundcloud"
        ? <SoundCloudPlayer 
            clientId={"AJqBAB1k7AZFSvc0eEeXAaiCwDCR9Eu8"}
            resolveUrl={"https://soundcloud.com/federico-bell/tracks"}
          />
        : */<BeatportPlayer
            tracks={this.props.parentProps.beatportTracks}
            streamUrl={this.props.parentProps.beatportTracks[0].streamUrl}
          />
        }
      </div>
    );
  }
}

export default TrackPlayer
