import React from 'react'

import './all.sass'

const Footer = (props) => {

  return (
    <div className="footerContainer">
      <div
        className="customFooter"
        style={{
          backgroundPosition: 'bottom right',
          height: props.height * 0.4
        }}
      >
        <h1 className="footerText">{props.language === 'en' ? "Booking Agent:" : "Contrataciones:"}</h1>
        <div className="bookingInfo">
          <h1 className="footerText ftBold">{"Leandro Santoro"}</h1>
          <h1 className="footerText footerTextSmall">{props.parentProps.bookingOne}</h1>
          <h1 className="footerText footerTextSmall">{props.parentProps.bookingTwo}</h1>
        </div>
        <img alt="" height="140" width="140" className="footerImage" src="/img/familyGroupBlack.png"/>
      </div>
      <div
        className="mobileFooter"
        style={{
          backgroundPosition: 'bottom right',
          height: props.height * 0.4
        }}
      >
        <h1 className="footerText footerTitle">{props.language === 'en' ? "Booking Agent" : "Contrataciones"}</h1>
        <div className="bookingInfo">
          <h1 className="footerText ftBold">{"Leandro Santoro"}</h1>
          <h1 className="footerText footerTextSmall">{props.parentProps.bookingOne}</h1>
          <h1 className="footerText footerTextSmall">{props.parentProps.bookingTwo}</h1>
        </div>
        <div className="mobileFooterImagesContainer">
          <img alt="" height="25%" width="25%" className="footerImage" src="/img/familyGroupBlack.png"/>
        </div>
      </div>
      <div className="copyrightBar" style={{ height: props.height * 0.07 }}>
        <h1 className="copyrightText">{props.language === 'en' ? "© Federico Bell 2019 . All rights reserved." : "© Federico Bell 2019 . Todos los derechos reservados."}</h1>
        <h1 className="smallCopyrightText">© Federico Bell 2019</h1>
      </div>
    </div>
  );

}

export default Footer