import React from 'react';
import { withPrefix } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight, faChevronCircleLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import Img from "gatsby-image";

import './all.sass'

class EventsCarousel extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      eventContainerWidth: 0,
      selectedEvent: null
    };

    this.carouselRef = React.createRef();
  }

  componentDidMount = () => {
    this.setState({ eventContainerWidth: window.document.documentElement.clientWidth / 4 });
  }

  onClickEvent = (index) => {
    this.setState({ selectedEvent: index });
  }

  closeOverlay = () => {
    this.setState({ selectedEvent: null });
  }

  scrollCarouselLeft = () => {
    if (this.carouselRef) this.carouselRef.current.scrollLeft -= this.state.eventContainerWidth;
  }

  scrollCarouselRight = () => {
    if (this.carouselRef) this.carouselRef.current.scrollLeft += this.state.eventContainerWidth;
  }

  render() {
    
    let eventOverlay = null;
    if (this.state.selectedEvent !== null) {
      eventOverlay = (
        <div className="eventOverlay" onClick={this.closeOverlay} style={{ height: this.props.height }}>
          <button className="eventOverlayBtn" onClick={this.closeOverlay}>
            <FontAwesomeIcon icon={faTimes} style={{ fontSize: '2.5em' }} size="lg" color="#fff" />
          </button>
          <img
            className="eventOverlayImage"
            onClick={this.closeOverlay}
            src={withPrefix(!!this.props.events[this.state.selectedEvent].image.childImageSharp
              ? this.props.events[this.state.selectedEvent].image.childImageSharp.normal.src
              : this.props.events[this.state.selectedEvent].image)} 
            alt="Logo"
          />
        </div>
      );
    }

    const events = this.props.events.map((event, index) => {
      return (
        <div 
          key={index}
          className="eventContainer"
          onClick={() => this.onClickEvent(index)}
          style={{ 
            width: this.state.eventContainerWidth
          }} 
        >
          <Img
            className="eventContainerImage"
            fluid={event.image.childImageSharp[this.props.webPSupport ? 'webP' : 'normal']}
            alt="Gatsby Docs are awesome"
          />
        </div>
      );
    });

    return (
      <div className="datesContainer">
        {eventOverlay}

        <div className="playlistControls eventsSwitchBtnsContainer" style={{ justifyContent: 'space-evenly', width: '22%' }}>
          <button className="eventsSwitchBtn" onClick={() => this.props.switchEvents('past')}>
            <h1 style={this.props.eventsType === 'past' ? {fontSize: 25} : {fontSize: 14}} className="eventsSwitchBtnText">{this.props.language === 'en' ? "PAST" : "PASADOS"}</h1>
          </button>
          <h1 className="eventsSwitchBtnText" style={{fontSize: 14}}> / </h1>
          <button className="eventsSwitchBtn" onClick={() => this.props.switchEvents('future')}>
            <h1 style={this.props.eventsType === 'future' ? {fontSize: 25} : {fontSize: 14}} className="eventsSwitchBtnText">{this.props.language === 'en' ? "UPCOMING" : "PRÓXIMOS"}</h1>
          </button>
        </div>
        <div className="carouselContainer">
            <div style={{ width: this.state.eventContainerWidth * this.props.events.length }} ref={this.carouselRef} className="carousel">
              {events}
            </div>
        </div>
        <div className="playlistControls" style={{ justifyContent: 'center', width: '10%' }}>
          <button className="playlistControlsBtn eventsCarouselControlBtns" onClick={this.scrollCarouselLeft}>
            <FontAwesomeIcon icon={faChevronCircleLeft} style={{ fontSize: '2.5em' }} size="lg" color="#333" />
          </button>
          <button className="playlistControlsBtn eventsCarouselControlBtns" onClick={this.scrollCarouselRight}>
            <FontAwesomeIcon icon={faChevronCircleRight} style={{ fontSize: '2.5em' }} size="lg" color="#333" />
          </button>
        </div>
      </div>
    );
  }
};

export default EventsCarousel;