import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSoundcloud, faFacebookSquare, faInstagram, faSpotify } from '@fortawesome/free-brands-svg-icons'

import './all.sass'

const Social = (props) => {
  return (
    <div style={props.style} className="iconsContainer">
      <button disabled={props.aboutOpen} className="socialBtn" style={props.aboutOpen ? {cursor: 'default', paddingTop: 6} : {cursor: 'pointer', paddingTop: 6}} onClick={() => window.open("https://www.facebook.com/FamilyGroupAg/")}>
        <img alt="" height="50" width="50" src="/img/familyGroup.png"/>
      </button>
      <button disabled={props.aboutOpen} style={props.aboutOpen ? {cursor: 'default'} : {cursor: 'pointer'}} className="socialBtn" onClick={() => window.open("https://soundcloud.com/federico-bell")}>
        <FontAwesomeIcon icon={faSoundcloud} className="socialIcon" size="lg" color="#fff" />
      </button>
      <button disabled={props.aboutOpen} style={props.aboutOpen ? {cursor: 'default'} : {cursor: 'pointer'}} className="socialBtn" onClick={() => window.open("https://www.facebook.com/Federico-Bell-1594825960787753/")}>
        <FontAwesomeIcon icon={faFacebookSquare} className="socialIcon" size="lg" color="#fff" />
      </button>
      <button disabled={props.aboutOpen} style={props.aboutOpen ? {cursor: 'default'} : {cursor: 'pointer'}} className="socialBtn" onClick={() => window.open("https://www.instagram.com/federicobell/")}>
        <FontAwesomeIcon icon={faInstagram} className="socialIcon" size="lg" color="#fff" />
      </button>
      <button disabled={props.aboutOpen} className="socialBtn" style={props.aboutOpen ? {cursor: 'default', paddingTop: 6} : {cursor: 'pointer', paddingTop: 6}} onClick={() => window.open("https://www.beatport.com/artist/federico-bell/543221")}>
        <img alt="" height="27" width="27" src="/img/beatport.png"/>
      </button>
      <button disabled={props.aboutOpen} style={props.aboutOpen ? {cursor: 'default'} : {cursor: 'pointer'}} className="socialBtn" onClick={() => window.open("https://open.spotify.com/artist/1Ud1DFPLvnXLwc78Q6bIgc")}>
        <FontAwesomeIcon icon={faSpotify} className="socialIcon" size="lg" color="#fff" />
      </button>
    </div>
  );
}

export default Social