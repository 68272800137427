import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleUp, faChevronCircleDown, faPlay, faPause, faStepBackward, faStepForward } from '@fortawesome/free-solid-svg-icons'
import { withSoundCloudAudio } from 'react-soundplayer/addons';
import { Timer, Progress } from 'react-soundplayer/components';

import './all.sass'

class SoundCloudPlayer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
      playing: false,
      loading: false
    };

    this.playlistRef = React.createRef();
  }

  playTrackAtIndex = (index) => {
    const { soundCloudAudio } = this.props;

    this.setState({ loading: true });

    soundCloudAudio.play({ playlistIndex: index }).then(
      this.setState({
        activeIndex: index,
        playing: true,
        loading: false
      })
    );
  }

  onPrevClick = () => {
    let { activeIndex } = this.state;
    const { soundCloudAudio } = this.props;

    if (activeIndex === 0) {
      return;
    }

    soundCloudAudio.previous();

    this.setState({activeIndex: activeIndex - 1, playing: true});
  }

  onNextClick = () => {
    let { activeIndex } = this.state;
    const { soundCloudAudio, playlist } = this.props;

    if (activeIndex === playlist.tracks.length - 1) {
      return;
    }

    soundCloudAudio.next();

    this.setState({activeIndex: activeIndex + 1, playing: true});
  }

  onPlayClick = () => {
    const { soundCloudAudio } = this.props;

    if (this.state.playing) soundCloudAudio.pause();
    else soundCloudAudio.play();

    this.setState((state, props) => ({ playing: !state.playing }));
  }

  scrollPlaylistUp = () => {
    if (this.playlistRef) this.playlistRef.current.scrollTop -= 30;
  }

  scrollPlaylistDown = () => {
    if (this.playlistRef) this.playlistRef.current.scrollTop += 30;
  }

  render() {

    let playlist;
    if (!this.props.playlist) {
      playlist = (
        <div className="loadingPlaceholderContainer">
          <p className="loadingPlaceholder">Loading...</p>
        </div>
      );
    } else {
      playlist = this.props.playlist.tracks.map((track, index) => {
        return (
          <div key={index} style={ index === this.state.activeIndex ? {backgroundColor: '#475C7A'} : {backgroundColor: 'transparent'} } className="trackContainer">
            <button className="trackTitleBtn" onClick={() => this.playTrackAtIndex(index)}>
              <p className="trackTitleText">{track.title}</p>
            </button>
            <span className="timeText">{Timer.prettyTime(track.duration / 1000)}</span>
          </div>
        );
      });
    }

    return (
      <div style={this.props.style} className="trackPlayerContainer">
        <div className="playlistContainer">
          <div className="playlist" ref={this.playlistRef}>
            {playlist}
          </div>
          <div className="playlistControls">
            <button className="playlistControlsBtn" onClick={this.scrollPlaylistUp}>
              <FontAwesomeIcon icon={faChevronCircleUp} className="socialIcon" size="lg" color="#fff" />
            </button>
            <button className="playlistControlsBtn" onClick={this.scrollPlaylistDown}>
              <FontAwesomeIcon icon={faChevronCircleDown} className="socialIcon" size="lg" color="#fff" />
            </button>
          </div>
        </div>
        <div className="controlsContainer">
          { (this.state.loading || !this.props.playlist)
          ? <div className="trackInfoContainer">
              <h1 className="trackArtist">Loading...</h1>
            </div>
          : <div className="trackInfoContainer">
              <h1 className="trackTitle">{this.props.playlist.tracks[this.state.activeIndex].title}</h1>
              <h1 className="trackArtist">{this.props.playlist.tracks[this.state.activeIndex].user.username}</h1>
            </div> 
          }
          { this.state.playing
          ? <Progress
              className="progressBar"
              innerClassName="progressBarInner"
              value={(this.props.currentTime / this.props.duration) * 100 || 0}
              {...this.props} 
            />
          : <div></div>
          }
          <div className="btnControls">
            <button disabled={!this.props.playlist} className="controlBtn secondaryBtn" onClick={this.onPrevClick}>
              <FontAwesomeIcon icon={faStepBackward} className="socialIcon" size="lg" color="#fff" />
            </button>
            <button className="controlBtn primaryBtn" onClick={this.onPlayClick}>
              { this.state.playing
              ? <FontAwesomeIcon icon={faPause} className="socialIcon" size="lg" color="#fff" />
              :<FontAwesomeIcon icon={faPlay} className="socialIcon" size="lg" color="#fff" />
              }
            </button>
            <button disabled={!this.props.playlist} className="controlBtn secondaryBtn" onClick={this.onNextClick}>
              <FontAwesomeIcon icon={faStepForward} className="socialIcon" size="lg" color="#fff" />
            </button>
          </div>
        </div>
      </div>
    );
  }
};

SoundCloudPlayer.propTypes = {
  resolveUrl: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired
};

export default withSoundCloudAudio(SoundCloudPlayer);