import React from 'react'

import './all.sass'

const Navbar = (props) => {
  const languageParam = props.language === 'en' ? 'es' : 'en';

  return (
    <div style={props.style} className="navbarContainer">
      <div className="languageOptions">
        <button type="button" disabled={props.disabled} style={props.disabled ? {cursor: 'default'} : {cursor: 'pointer'}} onClick={() => props.switchLanguage(languageParam)} className="navbarLink langBtn">{props.language === 'en' ? 'ENGLISH' : 'ESPAÑOL'}</button>
      </div>
      <div className="navBtnsContainer">
        <button type="button" disabled={props.disabled} style={props.disabled ? {cursor: 'default'} : {cursor: 'pointer'}} onClick={props.toggleAboutFragment} className="navbarLink">{props.language === 'en' ? "ABOUT" : "BIO"}</button>
        <button type="button" disabled={props.disabled} style={props.disabled ? {cursor: 'default'} : {cursor: 'pointer'}} onClick={() => props.scrollTo('tracks')} className="navbarLink">{props.language === 'en' ? "MUSIC" : "MUSICA"}</button>
        <button type="button" disabled={props.disabled} style={props.disabled ? {cursor: 'default'} : {cursor: 'pointer'}} onClick={() => props.scrollTo('dates')} className="navbarLink">{props.language === 'en' ? "DATES" : "EVENTOS"}</button>
        <button type="button" disabled={props.disabled} style={props.disabled ? {cursor: 'default'} : {cursor: 'pointer'}} onClick={() => props.scrollTo('sets')} className="navbarLink">VIDEOS</button>
        <button type="button" disabled={props.disabled} style={props.disabled ? {cursor: 'default'} : {cursor: 'pointer'}} onClick={() => props.scrollTo('booking')} className="navbarLink">BOOKING</button>
      </div>
    </div>
  );
}

export default Navbar